
import { defineComponent } from "vue";
// import SalesOrderDraftOrdersDetail from "@/views/sales-order/draft-orders/CommonDetail.vue";
import DoubleSaleOrderDraftOrdersDetail from "./CommonDetail.vue";

export default defineComponent({
  name: "double-sales-order-draft-order-detail",
  components: {
    // SalesOrderDraftOrdersDetail,
    DoubleSaleOrderDraftOrdersDetail,
  },
});
